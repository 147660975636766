/**
  Will return the correct hue for the palette as a custom css variable.

  e.g. --dqn_color_primary_500
 */
@function get-color($palette, $hue: 500) {
  @return var(--dqn_color_#{$palette}_#{$hue});
}

/**
  Will return the correct contrast hue for the palette as a custom css variable.

  e.g. --dqn_color_primary_contrast_500
 */
@function get-color-contrast($palette, $hue: 500) {
  @return var(--dqn_color_#{$palette}_contrast_#{$hue});
}

/*
  Will generate custom css variables for the provided palette in the following schema:

  "--dqn_color_{palette}_{hue}"

  Should be used with `theme-color()` to use the current theme.
 */
@mixin theme-color-variables-from-palette($palette, $name) {
  @each $hue, $color in $palette {
    @if (type-of($color) != 'map') {
      --dqn_color_#{$name}_#{$hue}: #{$color};
    } @else {
      // Iterate over contrast colors
      @include theme-color-variables-from-palette($color, "#{$name}_contrast");
    }
  }
}
