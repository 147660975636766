@use '@angular/material' as mat;
@use '@dqn/components-library' as dqn;

@use "variables" as *;
@use "src/scss/theme-helpers" as theme;

$app-palette-primary: mat.define-palette($app-blue);
$app-palette-accent: mat.define-palette($app-orange);
$app-palette-warn: mat.define-palette(mat.$red-palette);

$app-palette-button-primary: mat.define-palette($app-orange);
$app-palette-green: mat.define-palette($app-green);
$app-palette-toolbar-primary: mat.define-palette($app-blue);
$app-palette-toolbar-accent: mat.define-palette($app-blue, 900, 900, 900);

$app-typography: mat.define-legacy-typography-config(
        $font-family: $app-font-family,
        $headline: mat.define-typography-level(32px, 48px, 700),
        $title: mat.define-typography-level(24px, 48px, 700),
        $subheading-2: mat.define-typography-level(18px, 32px, 700),
        $body-1: mat.define-typography-level(16px, 24px, 500),
);

$app-theme-button: mat.define-light-theme((
        color: (
                primary: $app-palette-button-primary,
                accent: $app-palette-accent,
                warn: $app-palette-warn,
        ),
        typography: $app-typography,
));

$app-theme-green: mat.define-light-theme((
        color: (
                primary: $app-palette-green,
                accent: $app-palette-accent,
                warn: $app-palette-warn,
        ),
        typography: $app-typography,
));

$app-theme-toolbar: mat.define-light-theme((
        color: (
                primary: $app-palette-toolbar-primary,
                accent: $app-palette-toolbar-accent,
                warn: $app-palette-warn,
        ),
        typography: $app-typography,
));

@function my-mat-light-theme-foreground($color) {
  @return (
          base: $color,
          divider: rgba(black, 0.12),
          dividers: rgba(black, 0.12),
          disabled: rgba(black, 0.38),
          disabled-button: rgba($color, 0.26),
          disabled-text: rgba(black, 0.38),
          elevation: $color,
          hint-text: rgba(black, 0.38),
          secondary-text: rgba(black, 0.54),
          icon: rgba($color, 0.54),
          icons: rgba($color, 0.54),
          text: rgba($color, 0.87),
          slider-min: rgba($color, 0.87),
          slider-off: rgba($color, 0.26),
          slider-off-active: rgba($color, 0.38)
  );
}

$app-theme: mat.define-light-theme((
        color: (
                primary: $app-palette-primary,
                accent: $app-palette-accent,
                warn: $app-palette-warn
        ),
        typography: $app-typography,
));

$app-theme-foreground-color: mat.get-color-from-palette(map-get($app-theme, primary), 900);
$app-theme-foreground: my-mat-light-theme-foreground($app-theme-foreground-color);

$app-theme: map-merge(
                $app-theme,
                (
                        foreground: $app-theme-foreground,
                )
);


// Apply default app theme & typography to material components
@include mat.legacy-core();
@include mat.legacy-typography-hierarchy($app-typography);
@include mat.legacy-core-theme($app-theme);

@include mat.legacy-autocomplete-theme($app-theme);
@include mat.legacy-button-theme($app-theme-button);
@include mat.button-toggle-theme($app-theme);
@include mat.legacy-card-theme($app-theme);
@include mat.legacy-checkbox-theme($app-theme);
@include mat.legacy-chips-theme($app-theme);
@include mat.datepicker-theme($app-theme);
@include mat.legacy-dialog-theme($app-theme);
@include mat.divider-theme($app-theme);
@include mat.expansion-theme($app-theme);
@include mat.legacy-form-field-theme($app-theme);
@include mat.legacy-input-theme($app-theme);
@include mat.icon-theme($app-theme);
@include mat.legacy-list-theme($app-theme);
@include mat.legacy-menu-theme($app-theme);
@include mat.legacy-progress-bar-theme($app-theme);
@include mat.legacy-progress-spinner-theme($app-theme);
@include mat.legacy-radio-theme($app-theme);
@include mat.legacy-select-theme($app-theme);
@include mat.sidenav-theme($app-theme);
@include mat.legacy-slide-toggle-theme($app-theme);
@include mat.legacy-snack-bar-theme($app-theme);
@include mat.stepper-theme($app-theme);
@include mat.legacy-table-theme($app-theme);
@include mat.legacy-tabs-theme($app-theme);
@include mat.toolbar-theme($app-theme-toolbar);
@include mat.legacy-tooltip-theme($app-theme);

.ekp-green {
  @include mat.legacy-button-color($app-theme-green);
}

@include dqn.core-theme($app-theme);

@include dqn.autocomplete-theme($app-theme);
@include dqn.button-theme($app-theme);
@include dqn.checkbox-theme($app-theme);
@include dqn.dropdown-theme($app-theme);
@include dqn.form-field-theme($app-theme);
@include dqn.filter-theme($app-theme);
@include dqn.input-theme($app-theme);
@include dqn.paginator-theme($app-theme);
@include dqn.pill-theme($app-theme);
@include dqn.table-theme($app-theme);
@include dqn.tooltip-theme($app-theme);

// HINT: Override default styling of autocomplete until full theming is possible
.dqn-form-field:not(.dqn-form-field-disabled).dqn-focused .dqn-form-field-wrapper {
  border-color: mat.get-color-from-palette(map-get($app-theme, primary), 500) !important;
}

// HINT: Font family is not applied to tooltip by default -> Remove as soon as font family is added to tooltip
.dqn-tooltip {
  font-family: mat.font-family($app-typography);
}

:root {
  @each $name, $palette in $palettes {
    @include theme.theme-color-variables-from-palette($palette, $name);
  }
}
