@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  // TODO: Remove this as soon as it is supported by tailwind - https://github.com/tailwindlabs/tailwindcss/discussions/12127
  .break-anywhere {
    overflow-wrap: anywhere;
  }
}
