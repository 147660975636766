/* You can add global styles to this file, and also import other style files */

@use "./scss/global";
@use "./scss/tailwind";

@use "./scss/variables";
@use "./scss/theme";

// Include the material icons
@use "./scss/material-icons";
@use "./scss/fonts";

:root {
  font-size: 16px;
}

ul, ol, li {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
  color: inherit;
}
