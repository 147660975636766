﻿@use 'variables' as *;

button.mat-raised-button {
  text-transform: uppercase;
}

table tr.mat-row:hover {
  background-color: $app-color-grey-background;
}

/***** Remove scrollbar vom MatDrawer (sidenav) component *****/
mat-drawer.sidenav::-webkit-scrollbar {
  display: none;
}

mat-drawer.sidenav {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.eco {
  mat-icon {
    color: map-get($map: $app-green, $key: 500);
    font-size: 20px;
    height: 20px;
    vertical-align: text-bottom;
    width: 20px;
  }
}

/***** Remove scrollbar vom MatSelect components *****/
.mat-select-panel::-webkit-scrollbar {
  display: none;
}

.mat-select-panel {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  max-height: 266px !important;
}

.underline {
  text-decoration: underline;
}

.mat-menu-panel.profile-menu {
  border-radius: 8px;
  box-shadow: 0px -4px 132px rgba(0, 0, 0, 0.05), 0px -1.6711px 55.1464px rgba(0, 0, 0, 0.0359427), 0px -0.893452px 29.4839px rgba(0, 0, 0, 0.0298054), 0px -0.500862px 16.5284px rgba(0, 0, 0, 0.025), 0px -0.266004px 8.77813px rgba(0, 0, 0, 0.0201946), 0px -0.11069px 3.65278px rgba(0, 0, 0, 0.0140573);
  min-width: 275px;

  .mat-menu-content {
    padding-bottom: 0;

    > div {
      .mat-menu-item {
        color: black;
        font-size: 17px;
        font-weight: 400;
        letter-spacing: 0.5px;
        line-height: 26px;

        .mat-icon {
          color: #777778;
          margin-right: 0 !important;
        }
      }

      .profile-user-email,
      .profile-user-name,
      .profile-tenant-name {
        margin: 0 0.5rem;
      }

      .profile-user-email,
      .profile-tenant-name {
        font-size: 12px;
        font-weight: 400;
        letter-spacing: 0.6px;
        line-height: 15px;
      }

      .profile-user-name {
        font-size: 17px;
        font-weight: 500;
        letter-spacing: 1.1px;
        line-height: 36px;
      }
    }

    img {
      height: 45px;
      width: 45px;
    }
  }
}

// Scrollbar design of dialogs
.mat-dialog-container {
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: $app-color-grey-background-light;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: $app-color-grey-light;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: rgba($app-color-grey, 0.6);
  }
}

// Temporary solution for multiline tooltip
// TODO: Replace with dqn tooltip
.multiline-tooltip {
  white-space: pre-line;
}

// Temporary solution for radio buttons
// TODO: Replace with dqn radio buttons
.dqn-radio-button-container, .dqn-radio-button-container .dqn-radio {
  &:hover {
    .dqn-radio-button {
      background-image: url("/assets/icons/circle-dot-hover.svg");
    }
  }

  .dqn-radio-button {
    width: 1.5rem;
    height: 1.5rem;
    padding: 1px;
    background-image: url("/assets/icons/circle.svg");
    background-position: center;
    background-repeat: no-repeat;

    &.dqn-radio-button-selected {
      background-image: url("/assets/icons/circle-dot.svg");
    }
  }
}
